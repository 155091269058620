
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker, Layout, Divider } from 'antd';
import Settings from '../../dependencies/custom/settings';
import Input from 'antd/es/input/Input';
import { UserOutlined, LockOutlined } from '@ant-design/icons'


const CheckIndexNo = (props) => {
    const valuesStore = ValuesStore();
    const [applicantID, setApplicantID] = useState();
    const [details, setDetails] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [recordFound, setRecordFound] = useState(false);
    const [schoolDetails, setSchoolDetails] = useState();
    const [url, setURL] = useState();
    async function check() {
        setBtnLoading(true);
        const res = await utils.request('post', url, null, { applicant_id: applicantID?.trim() });
        if (res?.status && res?.details?.length) {
            let p = res.details[0];
            if (p.index_no == applicantID) {
                p['index_no'] = 'Your index will soon be generated. Please check later.';
            }
            setDetails(p);
            setRecordFound(true);
        } else {
            utils.showNotification(undefined, 'Could not find data');
            setDetails([]);
            setRecordFound(false);
        }
        setBtnLoading(false);
    }


    useMemo(() => {
        // const institution = valuesStore.getArrayObjectsValue('settings', 'prop', 'INSTITUTION_DETAILS')?.value;
        // if (institution) {
        //     setSchoolDetails(JSON.parse(institution));
        // }
        // const link = valuesStore.getArrayObjectsValue('settings', 'prop', 'indexNoCheckURL')?.value;
        // console.log(valuesStore.getValue('settings'))
        // if (link) {
        //     setURL(link);
        // }

    }, []);


    return (
        <>
            <div className='container'>

                <div className='row'>
                    <Divider className='teal-text'><label className='h1'>Index Number Checker</label></Divider>
                    <div className='col-sm-12 col-md-6 col-lg-6 mx-auto mb-2 borderx special-colorx rounded' style={{ marginTop: '2%' }}>
                        <Space className='w-100' direction='vertical'>
                            <div className='row'>
                                <div className='p-2 col-6x'>
                                    <div className='d-flex justify-content-center special-colorx rounded' >
                                    <img className='mx-auto mb-3' width={190} src={`${Settings.backend}/fav.png`} />
                                        {/* <img className='mx-auto mb-3' width={schoolDetails?.logoWidth} src={`${Settings.backend}/${schoolDetails?.logo}`} /> */}
                                    </div>
                                    <Input onPressEnter={e => check()} className='mb-2x rounded' type='text' prefix={<UserOutlined className="site-form-item-icon" />} placeholder='Enter your applicant ID' value={applicantID} onChange={e => setApplicantID(e.target.value)} />

                                    <Button loading={btnLoading} className='btn-success border-0 mt-2' onClick={e => check()}><i className='fas fa-paper-plane me-2' />Check</Button>

                                </div>

                                {recordFound ? <div className='col-6x border-startx p-2' style={{ fontSize: '14pt' }}>
                                    <label className='fw-bold text-danger'>Index Number: {details.index_no}</label><br />
                                    <label style={{ color: '#3F729B' }} className='fw-bold'>First Name: {details.fname}</label><br />
                                    <label style={{ color: '#3F729B' }} className='fw-bold'>Last Name: {details.lname}</label><br />
                                    <label style={{ color: '#3F729B' }} className='fw-bold'>Middle Name: {details.mname}</label><br />
                                    <label style={{ color: '#3F729B' }} className='fw-bold'>Program: {details.name}</label><br />
                                    <label style={{ color: '#3F729B' }} className='fw-bold'>Session: {details.session}</label>
                                </div> : <></>}

                            </div>

                        </Space>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(CheckIndexNo);


